(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

document.addEventListener('DOMContentLoaded', function (e) {

    var subscribeForm = document.getElementById('aiclo-subscribe-form');
    var controller = void 0;
    var signal = void 0;

    subscribeForm.addEventListener('submit', function (e) {
        e.preventDefault();

        // reset the form messages
        resetMessages();

        // ajax http post request
        var url = subscribeForm.dataset.url;
        var params = new URLSearchParams(new FormData(subscribeForm));

        subscribeForm.querySelector('.js-form-submission').classList.add('show');
        document.getElementById("sbmt").disabled = true;

        if (controller !== undefined) {
            // Cancel the previous request
            controller.abort();
        }

        // Feature detect
        if ("AbortController" in window) {
            controller = new AbortController();
            signal = controller.signal;
        }

        fetch(url, {
            signal: signal,
            method: "POST",
            body: params,
            mode: 'cors'
        }).then(function (res) {
            return res.json();
        }).catch(function (error) {
            resetMessages();
            subscribeForm.querySelector('.js-form-error').classList.add('show');
            document.getElementById("sbmt").disabled = false;
        }).then(function (response) {
            resetMessages();

            if (response === 0 || typeof response === 'undefined' || response.status === 'error') {
                if (typeof response !== 'undefined' && response.message) {
                    subscribeForm.querySelector('.js-form-error-text').innerHTML = response.message;
                }
                if (typeof response !== 'undefined' && response.errors) {
                    printErrorMsg(subscribeForm, response.errors);
                }
                subscribeForm.querySelector('.js-form-error').classList.add('show');
                document.getElementById("sbmt").disabled = false;
                return;
            }

            subscribeForm.querySelector('.js-form-success').classList.add('show');
            //                    subscribeForm.reset();
            document.getElementById("sbmt").disabled = false;
        });
    });
});

function resetMessages() {
    document.querySelectorAll('.field-msg').forEach(function (f) {
        return f.classList.remove('show');
    });
    document.querySelectorAll('.is-invalid').forEach(function (f) {
        return f.classList.remove('is-invalid');
    });
    document.querySelectorAll('.invalid-feedback').forEach(function (f) {
        f.removeChild(f.firstChild);
        f.remove();
    });
}

function printErrorMsg(form, msg) {
    $.each(msg, function (key, value) {
        form.querySelector("[name='" + key + "']").classList.add("is-invalid");
        //        form.querySelector("[name='" + key + "']").insertAdjacentHTML('afterend', "<div class='invalid-feedback'>" + value + "</div>");
    });
}

},{}]},{},[1]);
